<template>
  <div class="auth-wrapper auth-v1 px-2" style="min-height: 100vh">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo align-items-center" to="/map">
          <!-- <logo /> -->
          <b-img :src="logo" alt="Ecoplot" width="40" height="40"></b-img>
          <h2 class="brand-text text-primary ml-1 mb-0">Ecoplot</h2>
        </b-link>

        <b-card-title class="mb-1">{{ $t('Reset_Your_Passsword') }}</b-card-title>
        <b-card-text class="mb-2">{{ $t("Please_enter_your_email_address_below.We'll_send_you_instructions_to_reset_your_password") }}</b-card-text>
        <!-- <button @click.stop="register">resgister</button> -->

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="sendEmail">
            <!-- email -->
            <b-form-group label-for="email" :label="$t('email')">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input tabindex="1" id="email" v-model="email" name="your-name" :state="errors.length > 0 ? false : null" :placeholder="$t('Enter_your_email')" />
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button tabindex="3" variant="primary" type="submit" block :disabled="invalid || showloading" class="btn-submit-login-reset">
              <span v-show="!showloading" class="text-signin">{{ $t('Send_Instructions') }}</span>
              <span v-show="showloading" class="loading-signin">
                <div style="width: 20px; height: 20px" class="spinner-border text-light" role="status"></div>
              </span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('Remember_your_password') }} </span>
          <b-link to="/login">
            <span>{{ $t('signin') }}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2"></div>
      </b-card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from '@/@core/utils/validations/validations.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
extend('required', {
  ...required,
  message: (field) => {
    if (field == 'Email') {
      return 'This_field_is_required'
    }
    if (field == 'Password') {
      return 'This_field_is_required'
    }
    if (field == 'new-pass') {
      return 'This_field_is_required'
    }
    if (field == 'confirm-pass') {
      return 'This_field_is_required'
    }
  },
})
extend('email', {
  ...required,
  message: (value) => {
    return 'Please_enter_a_valid_email'
  },
})
export default {
  name: 'Forget-password',
  components: { ToastificationContent, ValidationObserver, ValidationProvider },
  data() {
    return {
      required,
      email,
      logo: require('/static/besec.png'),
      showloading: false,
    }
  },
  mounted() {},
  computed: {
    responseForgetPassWord() {
      return this.$store.state.ecoplot.responseForgetPassWord
    },
  },
  watch: {
    responseForgetPassWord() {
      if (this.responseForgetPassWord && this.responseForgetPassWord.type && this.responseForgetPassWord.messages) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t(this.responseForgetPassWord.messages), icon: 'BellIcon', variant: this.responseForgetPassWord.type && this.responseForgetPassWord.type == 'error' ? 'danger' : this.responseForgetPassWord.type, timeout: 2000 } })
      }
      this.$store.commit('ecoplot/UPDATE_RESPONSE_FORGET_PASSWORD', null)
      this.showloading = false
    },
  },
  methods: {
    sendEmail() {
      this.showloading = true
      let domain = 'https://ecoplotpro.besec.co.jp'
      try {
        domain = window.location.origin
      } catch {}
      this.$store.dispatch('ecoplot/FORGET_PASSWORD', { username: this.email, url: `${domain}/#/reset-password/` })
    },
  },
}
</script>
<style scoped>
.or {
  position: relative;
  text-align: center;
}
.or span {
  display: inline-block;
  padding: 0 12px;
  font-weight: 600;
  color: #3b368c;
  text-transform: uppercase;
  background: #fff;
  position: relative;
  z-index: 1;
}
.or:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background: #dddce7;
}
.account-header {
  padding: 0 40px 40px;
  text-align: center;
}
.account-header .sign-in-with {
  min-width: 310px;
  justify-content: normal;
  margin-bottom: 10px;
}

.account-header .sign-in-with {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 40px;
  color: #504c89;
  border: 1px solid #aa9dd0;
  border-radius: 27px;
}
.account-header .sign-in-with img {
  width: 24px;
  margin-right: 10px;
}
</style>
<style lang="scss">
@import '@/@core/scss/vue/pages/page-auth.scss';
.btn-submit-login-reset {
  height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
